import React from 'react';
import 'simple-bulma';

function Login() {
  return (
    
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        
      </header>
    </div>
  );
}

export default Login;

