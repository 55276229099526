import React from "react";
import "simple-bulma";
import "../App.css";

function Home() {
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > document.documentElement.clientHeight ||
      document.documentElement.scrollTop > document.documentElement.clientHeight
    ) {
      document.getElementById("nav").style.backgroundColor = "cyan";
      document.getElementById("brand").style.color = "white";
    } else {
      document.getElementById("nav").style.backgroundColor = "white";
      document.getElementById("brand").style.color = "cyan";
    }
  }

  document.addEventListener("DOMContentLoaded", () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  });
  return (
    <div className="App">
      <nav
        id="nav"
        style={{ position: "fixed", width: "100%" }}
        className="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="index.html">
            <div id="brand" style={{ color: "cyan" }} className="title is-4">
              Homey
            </div>
          </a>

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <a className="navbar-item">About</a>
            <a className="navbar-item">Contact</a>
            <a className="navbar-item">List</a>
            <a className="navbar-item">App</a>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a
                  style={{
                    backgroundColor: "cyan",
                    border: "cyan",
                    color: "white",
                  }}
                  className="button is-light"
                >
                  <strong>Sign up</strong>
                </a>
                <a className="button is-light">Log in</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section class="hero hero2 is-fullheight">
        <div class="hero-body">
          <div class="container">
            <h1 class="title title2">
              HOME AWAY FROM <br />
              HOME
            </h1>
            <h2 class="subtitle subtitle2">DISCOVER HOMEY HOSTELS</h2>
            <div class="buttons">
              <button class="button is-primary is-light is-medium">
                Start Booking
              </button>
              <button class="button is-link is-light is-medium">
                Get Started
              </button>
            </div>
            <div className="notice">
              * Please note that we are currently only <br /> on University of
              Cape Coast campus
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <h1 class="title">New Listings</h1>
          <h2 class="subtitle">
            An unmatched partnership between hostel managers and{" "}
            <strong>homey</strong>, offering on demand hostels at affordable
            prices
          </h2>
          <div class="buttons">
            <button class="button is-primary is-light">View More</button>
            {/* <button class="button is-link is-light">Get Started</button> */}
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-4 is-vertical is-parent">
              <div id="tile" class="tile is-child box">
                <div className="con">
                  <div className="icon">
                    <img src={require("../images/business-outline.svg")} />
                  </div>
                  <div className="detail">
                    <div className="name">Hostel Name</div>
                    <div className="city">City</div>
                    <div className="price">GHC 1000 - 2000 / year</div>
                  </div>
                </div>
              </div>
              <div id="tile" class="tile is-child box">
                <div className="con">
                  <div className="icon">
                    <img src={require("../images/business-outline.svg")} />
                  </div>
                  <div className="detail">
                    <div className="name">Hostel Name</div>
                    <div className="city">City</div>
                    <div className="price">GHC 1000 - 2000 / year</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent">
              <div id="tile2" class="tile is-child box">
                <div className="con2">
                  <div className="icon">
                    <img src={require("../images/business-outline.svg")} />
                  </div>
                  <div className="detail">
                    <div className="name">Hostel Name</div>
                    <div className="city">City</div>
                    <div className="price">GHC 1000 - 2000 / year</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <h1 class="title">Choose the perfect area for your needs</h1>
          <h2 class="subtitle">
            You can choose the place/area that suits your taste and your budget.{" "}
            <strong>homey</strong> is everywhere prices
          </h2>
          <div class="buttons">
            <button class="button is-primary is-light">View More</button>
            {/* <button class="button is-link is-light">Get Started</button> */}
          </div>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-8">
              <div class="tile">
                <div class="tile is-parent is-vertical">
                  <div id="tile" class="tile is-child box">
                    <div className="con2">
                      <div className="icon">
                        <img src={require("../images/planet-outline.svg")} />
                      </div>
                      <div className="detail">
                        <div className="name">Hostel place</div>
                        <div className="city">Cape Coast</div>
                        <div className="price">50 Hostels</div>
                      </div>
                    </div>
                  </div>
                  <div id="tile" class="tile is-child box">
                    <div className="con2">
                      <div className="icon">
                        <img src={require("../images/planet-outline.svg")} />
                      </div>
                      <div className="detail">
                        <div className="name">Hostel place</div>
                        <div className="city">Cape Coast</div>
                        <div className="price">50 Hostels</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tile is-parent">
                  <div id="tile2" class="tile is-child box">
                    <div className="con2">
                      <div className="icon">
                        <img src={require("../images/planet-outline.svg")} />
                      </div>
                      <div className="detail">
                        <div className="name">Hostel place</div>
                        <div className="city">Cape Coast</div>
                        <div className="price">50 Hostels</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent">
              <div id="tile2" class="tile is-child box">
                <div className="con2">
                  <div className="icon">
                    <img src={require("../images/planet-outline.svg")} />
                  </div>
                  <div className="detail">
                    <div className="name">Hostel place</div>
                    <div className="city">Cape Coast</div>
                    <div className="price">50 Hostels</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="hero is-medium is-primary is-bold">
        <div class="hero-body">
          <div class="container dis-con">
            <div className="discount">
              <h1 class="title">Ready? We have a special discount for you</h1>
              <h2 style={{ marginTop: 30 }} class="subtitle">
                Start booking on homey with 15% discount on purchases above GHC
                2000.00
              </h2>
              <button class="button is-primary is-inverted is-medium">
                Book Now
              </button>
            </div>
            <div className="disc">20%</div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container" style={{ textAlign: "center" }}>
          <h1 class="title">Join our Family</h1>
          <h2 class="subtitle">
            Join other people like you using <strong>homey</strong> to book
            hostels now.
          </h2>
          <nav class="level is-mobile">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Bookings</p>
                <p class="title">0</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Followers</p>
                <p class="title">0</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Hostels</p>
                <p class="title">0</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Users</p>
                <p class="title">0</p>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <section class="hero is-small is-primary is-bold">
        <div class="hero-body">
          <div class="container contact-con">
            <div className="contact">
              <a href="index.html">
                <div
                  id="brand"
                  style={{ color: "white", marginTop: 20 }}
                  className="title is-4"
                >
                  Homey
                </div>
                <div
                  style={{ marginTop: 0, color: "#eee", fontSize: 15 }}
                  class="subtitle"
                >
                  +233 540 920 557
                </div>
                <div
                  class="subtitle"
                  style={{ marginTop: -20, color: "#eee", fontSize: 15 }}
                >
                  homey@cirlorm.dev
                </div>
              </a>
            </div>
            <div style={{ marginTop: 20 }} className="social">
              <h2 class="title is-4">Follow Us</h2>
              <div className="social-icons">
                <img src={require("../images/logo-twitter.svg")} />
                <img src={require("../images/logo-facebook.svg")} />
                <img src={require("../images/logo-instagram.svg")} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            <strong>Homey</strong> &copy; 2020 All Rights Reserved{" "}<br/>
            subsidiary of <a href="https://cirlorm.dev">cirlorm.</a><br/>
          </p>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
          <a style={{fontSize: 'smaller'}} href="https://cirlorm.dev/privacypolicy">Privacy Policy</a>
          <div style={{width: 10}} />
          <a style={{fontSize: 'smaller'}} href="https://cirlorm.dev/privacypolicy">Terms and Condition</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
