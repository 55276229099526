import React, {useState} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./screens/Home";
import Login from "./screens/Login";

function App() {
  // let [user, setUser] = useState(false)

  return (
    <Router>
      <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
    </Router>
  );
}

export default App;
